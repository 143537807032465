import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSectionConfiguration } from '../../models/ClientFormDefaults';
import { ClientFormTag, ClientFormUser, clientFormUsersToRequestDto } from '../../models/ClientFormUser';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { FormSection } from '../../models/Form';
import User from '../../models/User';
import LanguageUtils from '../../utils/LanguageUtils';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import Tooltip from '../shared/Tooltip';

type FormSectionDefaultsProps = {
  section: FormSection;
  configuration: FormSectionConfiguration | null;
  clientUsers: User[];
  onUsersChange: (configuration: FormSectionConfiguration) => void;
  onCreatorRoleChange: (configuration: FormSectionConfiguration) => void;
  requiresApproval: boolean;
  requiresValidation: boolean;
};

const FormSectionDefaults: FC<FormSectionDefaultsProps> = (props) => {
  const { section, configuration, clientUsers, onUsersChange, onCreatorRoleChange, requiresApproval, requiresValidation } = props;
  const { t } = useTranslation(['organisation', 'common']);

  const users = useMemo((): ClientFormUser[] => {
    if (configuration && clientUsers.length) {
      const users =
        configuration?.users?.flatMap((x) =>
          x.users.map((user) => {
            const clientUser = clientUsers.find((u) => u.id === user.userId);
            return { ...clientUser, role: x.role, formSectionId: section.id, tagIds: user.tagIds } as ClientFormUser;
          }),
        ) ?? [];

      return users;
    }
    return [];
  }, [clientUsers, configuration, section.id]);

  const tags = useMemo(() => {
    return (
      configuration?.users?.flatMap((x) =>
        x.tags.map((t) => ({
          ...t,
          role: x.role,
          users: configuration.users
            ?.filter((u) => u.tags.some((ut) => ut.id === t.id))
            .flatMap((u) => u.users.map((uu) => clientUsers.find((cu) => cu.id === uu.userId)))
            .filter(Boolean),
        })),
      ) ?? []
    );
  }, [clientUsers, configuration?.users]);

  const handleUsersChange = (users: ClientFormUser[], tags: ClientFormTag[]) => {
    const sectionConfiguration = {
      ...configuration,
      id: section.id,
      users: clientFormUsersToRequestDto(users, tags),
    } as FormSectionConfiguration;
    onUsersChange(sectionConfiguration);
  };

  const handleCreatorRoleChange = (role: ClientFormUserRoleValues | null) => {
    const sectionConfiguration = {
      ...configuration,
      id: section.id,
      creatorRole: role,
    } as FormSectionConfiguration;
    onCreatorRoleChange(sectionConfiguration);
  };

  const sectionTitle = useMemo(() => {
    return LanguageUtils.getTranslation('title', section.translations || {});
  }, [section.translations]);

  return (
    <div className="flex w-48 flex-col items-center px-2 text-center">
      <Tooltip text={sectionTitle} truncatedTextMode>
        {(tooltip) => {
          return (
            <div className="w-48 truncate" {...tooltip}>
              {sectionTitle}
            </div>
          );
        }}
      </Tooltip>

      <FormOwnership
        formStepId={section.id}
        users={users}
        tags={tags}
        size={OwnershipDisplayType.Tiny}
        modalHeading={t('organisation:forms-defaults.default-users-heading')}
        onUsersChange={handleUsersChange}
        onCreatorRoleChange={handleCreatorRoleChange}
        creatorRole={configuration?.creatorRole}
        requiresApproval={requiresApproval}
        requiresValidation={requiresValidation}
        shouldValidateRoles={false}
      />
    </div>
  );
};

export default FormSectionDefaults;
